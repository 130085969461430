@import '../node_modules/bootstrap/scss/bootstrap';
@import 'scss_partials/variables';
@import 'scss_partials/mixins';
@import '../node_modules/font-awesome/scss/font-awesome';
@import url('https://use.typekit.net/wae7xnv.css');
@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';

/* BEGIN: MyTS overrides for new bootstrap version
#menuForm {
  .dropdown-toggle::after {
    display: none;
  }

  + #containerHeadline {
    .dropdown-toggle::after {
      display: none;
    }
    > .row {
      display: block;
    }
  }



  .navbar {
    padding: 0;

    > .container {
      display: block;

      .navbar-collapse {
        .nav.navbar-nav {
          display: block;

          .dropdown-menu {
            position: absolute;
          }
        }
      }
    }
  }
}
/* END: MyTS overrides for new bootstrap version */

// General styles:
html {
  font-size: 62.5%;

  body {
    //background: url(ngassets/images/background.jpg) center top no-repeat;
    font-size: 1.4rem;
    @include roboto;
  }

  a {
    color: $color-link;
    text-decoration: none;

    &:hover {
      color: $color-link-hover;
      text-decoration: none;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  // Overwriting Bootstrap:
  .form-group {
    margin-bottom: .8rem;

    label {
      margin-bottom: .4rem;
    }

    .form-control, .btn {
      line-height: 1;
      font-size: 1.4rem;
    }
  }

  select.form-control:not([size]):not([multiple]) {
    height: 3.2rem;
  }

  .input-group .form-control:not(:last-child) {
    border-radius: .4rem;
    margin-right: .5rem;
  }

  button {
    border: none;
    padding: 0;
    cursor: pointer;
    color: $color-link;

    &:hover {
      color: $color-link-hover;
    }

    &.btn, &.btn:focus, &.btn:active {
      display: inline-block;
      position: relative;
      min-width: 18.5rem;
      max-width: 100%;
      padding: .6rem 2rem;
      border: .1rem solid #ddd;
      border-radius: .4rem;
      background: linear-gradient(0deg, #e8e8e8, #f5f5f5);
      color: $color-black;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.35;
      text-align: center;
      text-decoration: none;
      box-shadow: inset 0 0.1rem 0.1rem 0 #f9f9f9;
      cursor: pointer;

      &:disabled {
        border-color: $color-black20;
        background: $color-black10;
        color: $color-black50;
        box-shadow: none;
        cursor: default;
        pointer-events: none;
      }

      &:hover {
        border-color: #cecece;
        outline: none;
        background: linear-gradient(180deg, #efefef, #ddd);
        color: #000;
        box-shadow: inset 0 0.1rem 0.1rem 0 #f6f6f6;
      }

      &.btn-primary {
        border-color: #2755a1;
        background: linear-gradient(180deg, #397eed, #2b5fb3);
        color: $color-white;
        box-shadow: inset 0 0.1rem 0.1rem 0 #4a97f3;

        &:hover {
          border-color: #1d438f;
          background: linear-gradient(180deg,#2b6ae7 ,#1f499a);
          color: $color-white;
          box-shadow: inset 0 0.1rem 0.1rem 0 #3b87f1;
        }

        &:disabled {
          border-color: $color-black20;
          background: $color-black10;
          color: $color-black50;
          box-shadow: none;
          cursor: default;
          pointer-events: none;
        }
      }

      &.btn-ananas {
        border-color: #f0bf0e;
        background: linear-gradient(180deg, #ffdc0f, #f5cb0f);
        color: $color-black;
        box-shadow: inset 0 0.1rem 0.1rem 0 #ffe613;

        &:hover {
          border-color: #ebb10b;
          background: linear-gradient(180deg, #ffd10a, #f2bd0a);
          color: $color-black;
          box-shadow: inset 0 0.1rem 0.1rem 0 #ffe20e
        }

        &:disabled {
          border-color: $color-black20;
          background: $color-black10;
          color: $color-black50;
          box-shadow: none;
          cursor: default;
          pointer-events: none;
        }
      }
    }
  }
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-black60;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $color-black60;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: $color-black60;
}
