@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

@mixin roboto {
  font-family: 'Roboto', serif;
}

@mixin myriad-pro-light {
  font-family: 'myriad-pro', Arial, sans-serif;
  font-weight: 300;
}

@mixin myriad-pro-light-italic {
  font-family: 'myriad-pro', Arial, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin myriad-pro {
  font-family: 'myriad-pro', Arial, sans-serif;
  font-weight: 400;
}

@mixin myriad-pro-italic {
  font-family: 'myriad-pro', Arial, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin myriad-pro-bold {
  font-family: 'myriad-pro', Arial, sans-serif;
  font-weight: 700;
}

@mixin myriad-pro-bold-italic {
  font-family: 'myriad-pro', Arial, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin myriad-pro-black {
  font-family: 'myriad-pro', Arial, sans-serif;
  font-weight: 900;
}

@mixin myriad-pro-black-italic {
  font-family: 'myriad-pro', Arial, sans-serif;
  font-weight: 900;
  font-style: italic;
}

@mixin background-2x($path, $ext: "png", $w: auto, $h: auto, $pos: left top, $repeat: no-repeat) {

  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";

  background-image: url("#{$at1x_path}");
  background-size: $w $h;
  background-position: $pos;
  background-repeat: $repeat;

  @media all and (-webkit-min-device-pixel-ratio : 1.5),
  all and (-o-min-device-pixel-ratio: 3/2),
  all and (min--moz-device-pixel-ratio: 1.5),
  all and (min-device-pixel-ratio: 1.5) {
    background-image: url("#{$at2x_path}");
  }
}

